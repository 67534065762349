import request from '@/utils/scm-request'

// 物流时效配置-分页查询
export function getLogisticsAgingPage(data, params) {
  return request({
    url: `scm-purchase/logisticsAging/page`,
    method: 'post',
    data,
    params
  })
}

// 物流时效配置-导出
export function exportLogisticsAging(data) {
  return request({
    url: `scm-purchase/logisticsAging/export`,
    method: 'post',
    data,
    responseType: 'arraybuffer'
  })
}

// 物流时效配置-保存
export function saveLogisticsAging(data) {
  return request({
    url: `scm-purchase/logisticsAging/save`,
    method: 'post',
    data
  })
}

// TurnOver配置-保存
export function saveturnover(data) {
  return request({
    url: `scm-purchase/turnover/save`,
    method: 'post',
    data
  })
}

// TurnOver配置-查询
export function getTurnOverList(params) {
  return request({
    url: `scm-purchase/turnover/list`,
    method: 'get',
    params
  })
}

// 发运计划分页查询
export function getListDsbSku(data, params) {
  return request({
    url: `scm-purchase/sd/listDsbSku`,
    method: 'post',
    data,
    params
  })
}

// 导出明细
export function exportPurchaseOrderDetail(data) {
  return request({
    url: `/scm-purchase/export/purchaseOrderDetail`,
    method: 'post',
    data
  })
}

// 补货管理列表
export function getListByPage(data, params) {
  return request({
    url: `scm-purchase/replenish/listByPage`,
    method: 'post',
    data,
    params,
    timeout: 999999
  })
}

// 补货确认
export function postReplenish(data) {
  return request({
    url: `scm-purchase/replenish/replenish`,
    method: 'post',
    data
  })
}

// 补货确认生成调拨单
export function postReplenishNext(data) {
  return request({
    url: `scm-purchase/replenish/replenishNext`,
    method: 'post',
    data
  })
}

// 补货确认生成调拨单提交
export function postReplenishCommit(data) {
  return request({
    url: `scm-purchase/replenish/commit`,
    method: 'post',
    data
  })
}

// 补货管理导出
export function exportReplenish(data) {
  return request({
    url: `/scm-purchase/export/exportReplenish`,
    method: 'post',
    data
  })
}

// 需求计划导出
export function exportRequirePlan(data) {
  return request({
    url: `/scm-purchase/export/exportRequirePlan`,
    method: 'post',
    data
  })
}

// PR转PO第1步：采购计划单转采购订单校验
export function purchasePlanToOrderCheck(data) {
  return request({
    url: `/scm-purchase/plan/purchasePlanToOrderCheck`,
    method: 'post',
    data
  })
}

// PR转PO第0步：当前style未生成采购订单的计划单列表
export function purchasePlanToOrderOther(data) {
  return request({
    url: `/scm-purchase/plan/purchasePlanToOrderOther`,
    method: 'post',
    data
  })
}

// PR转PO第2步V2（调整了结构）：采购计划单转采购订单预览, 必须是相同style, 把平台站点改为备货渠道
export function purchasePlanToOrderPreviewV2(data) {
  return request({
    url: `/scm-purchase/plan/purchasePlanToOrderPreviewV2`,
    method: 'post',
    data
  })
}

// PR转PO第3步：采购计划单转采购订单
export function purchasePlanToOrderV2(data) {
  return request({
    url: `/scm-purchase/plan/purchasePlanToOrderV2`,
    method: 'post',
    data
  })
}

// 添加超买数量（只返回给前端一个列表）,查看超买明细进行尺码排序见
export function addOverbought(data) {
  return request({
    url: `/scm-purchase/plan/addOverbought`,
    method: 'post',
    data
  })
}

// 超买数量导出（前端提交数据，后端帮忙导出成Excel）
export function exportOverbought(data) {
  return request({
    url: `/scm-purchase/plan/exportOverbought`,
    method: 'post',
    data,
    responseType: 'arraybuffer'
  })
}

// 超买数量导入（前端提交Excel数据，后端读取数据给前端）
export function importOverbought(data) {
  return request({
    url: `/scm-purchase/plan/importOverbought`,
    method: 'post',
    data
  })
}

// 补货弹框第二步校验
export function replenishSkuValidate(data) {
  return request({
    url: `/scm-purchase/replenish/skuValidate`,
    method: 'post',
    data
  })
}

// 翻单弹框第二步校验
export function produceSkuValidate(data) {
  return request({
    url: `/scm-purchase/produce/skuValidate`,
    method: 'post',
    data
  })
}

// 补货建议生成
export function replenishSuggest(data) {
  return request({
    url: `scm-purchase/replenish/replenishSuggest`,
    method: 'post',
    data
  })
}

export function replenishNextMove(data) {
  return request({
    url: `scm-purchase/replenish/replenishNextMove`,
    method: 'post',
    data
  })
}
