var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container"},[_c('el-form',{ref:"ruleForm",staticClass:"demo-ruleForm",attrs:{"model":_vm.ruleForm,"rules":_vm.rules,"label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"平台/站点:","prop":"platformSiteList"}},[_c('el-cascader',{attrs:{"clearable":"","filterable":"","options":_vm.limitedAlldatas,"props":{ value: 'value', label: 'platformName', children: 'siteList'}},on:{"change":function () {
            _vm.getUserChange('site');
            _vm._getPage();
          }},model:{value:(_vm.ruleForm.platformSiteList),callback:function ($$v) {_vm.$set(_vm.ruleForm, "platformSiteList", $$v)},expression:"ruleForm.platformSiteList"}})],1),_c('el-form-item',{attrs:{"label":"物流类型:","prop":"logisticsTypeDict"}},[_c('el-radio-group',{attrs:{"disabled":_vm.isBan},on:{"change":function () {
            _vm.getUserChange('logisticsTypeDict');
            _vm._getPage();
          }},model:{value:(_vm.ruleForm.logisticsTypeDict),callback:function ($$v) {_vm.$set(_vm.ruleForm, "logisticsTypeDict", $$v)},expression:"ruleForm.logisticsTypeDict"}},[_c('el-radio',{attrs:{"label":"0"}},[_vm._v("全部")]),_vm._l((_vm.logisticsOptions),function(item){return _c('el-radio',{key:item.val,attrs:{"label":item.val,"value":item.val}},[_vm._v(" "+_vm._s(item.label)+" ")])})],2)],1),_c('el-form-item',{attrs:{"label":"运输方式:"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('el-checkbox',{attrs:{"disabled":_vm.isBan,"indeterminate":_vm.isIndeterminate},on:{"change":function (e) {
              _vm.handleCheckAllChange(e);
              _vm._getPage();
            }},model:{value:(_vm.ruleForm.checkAll),callback:function ($$v) {_vm.$set(_vm.ruleForm, "checkAll", $$v)},expression:"ruleForm.checkAll"}},[_vm._v("全选")]),_c('div',{staticStyle:{"margin":"0px 15px"}}),_c('el-checkbox-group',{attrs:{"disabled":_vm.isBan},on:{"change":function (e) {
              _vm.handleCheckedCitiesChange(e);
              _vm._getPage();
            }},model:{value:(_vm.ruleForm.checked),callback:function ($$v) {_vm.$set(_vm.ruleForm, "checked", $$v)},expression:"ruleForm.checked"}},_vm._l((_vm.transportOptions),function(list){return _c('el-checkbox',{key:list.val,attrs:{"label":list.val}},[_vm._v(_vm._s(list.label))])}),1)],1)]),_c('div',{staticClass:"submit"},[_c('div',[(!_vm.isEdit)?_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:('edit'),expression:"'edit'"}],attrs:{"type":"primary","disabled":!_vm.tableData.length,"loading":_vm.exportLoading},on:{"click":function($event){return _vm.edit(true)}}},[_vm._v(" 编辑 ")]):_vm._e(),(_vm.isEdit)?_c('el-button',{attrs:{"type":"primary","loading":_vm.exportLoading},on:{"click":function($event){return _vm.saveForm()}}},[_vm._v(" 保存 ")]):_vm._e(),(_vm.isEdit)?_c('el-button',{attrs:{"loading":_vm.exportLoading},on:{"click":function($event){return _vm.cancel(false)}}},[_vm._v("取消")]):_vm._e()],1),_c('div',[_c('el-button',{attrs:{"loading":_vm.exportLoading,"disabled":_vm.isBan},on:{"click":function($event){return _vm.resetForm('ruleForm')}}},[_vm._v("重置")]),_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:('exportA'),expression:"'exportA'"}],attrs:{"loading":_vm.exportLoading,"type":"primary","disabled":_vm.isBan},on:{"click":_vm.exportLogistics}},[_vm._v(" 导出 ")])],1)])],1),(_vm.tableData.length)?_c('div',{staticClass:"table_con"},[_c('vxe-table',{ref:"xTable",attrs:{"border":"","resizable":"","show-overflow":"","data":_vm.tableData,"align":"center","edit-rules":_vm.validRules,"edit-config":_vm.isEdit ? { trigger: 'click', mode: 'cell' } : {}}},[_c('vxe-column',{attrs:{"type":"checkbox","width":"60"}}),_c('vxe-column',{attrs:{"field":"siteName","title":"平台/站点"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_vm._v(" "+_vm._s(((row.platformName) + "/" + (row.siteName)))+" ")]}}],null,false,972928960)}),_c('vxe-column',{attrs:{"field":"logisticsTypeI18","title":"物流类型"}}),_c('vxe-column',{attrs:{"field":"transportModeDict","title":"运输方式","edit-render":{}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('vxe-select',{attrs:{"disabled":!_vm.isEdit,"transfer":""},model:{value:(row.transportModeDict),callback:function ($$v) {_vm.$set(row, "transportModeDict", $$v)},expression:"row.transportModeDict"}},_vm._l((_vm.transportOptions),function(item){return _c('vxe-option',{key:item.val,attrs:{"value":item.val,"label":item.label}})}),1)]}},{key:"edit",fn:function(ref){
            var row = ref.row;
return [_c('vxe-select',{attrs:{"transfer":""},model:{value:(row.transportModeDict),callback:function ($$v) {_vm.$set(row, "transportModeDict", $$v)},expression:"row.transportModeDict"}},_vm._l((_vm.transportOptions),function(item){return _c('vxe-option',{key:item.val,attrs:{"value":item.val,"label":item.label}})}),1)]}}],null,false,736102431)}),_c('vxe-column',{attrs:{"field":"transportDays","title":"运输天数","edit-render":{ autofocus: '.vxe-input--inner' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.transportDays))])]}},{key:"edit",fn:function(ref){
            var row = ref.row;
return [_c('vxe-input',{attrs:{"placeholder":"请输入内容","min":"1","type":"integer"},model:{value:(row.transportDays),callback:function ($$v) {_vm.$set(row, "transportDays", $$v)},expression:"row.transportDays"}})]}}],null,false,2676025730)}),_c('vxe-column',{attrs:{"field":"remark","title":"备注","edit-render":{ autofocus: '.vxe-input--inner' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.remark))])]}},{key:"edit",fn:function(ref){
            var row = ref.row;
return [_c('vxe-input',{attrs:{"maxlength":"256","type":"text","placeholder":"请输入内容"},model:{value:(row.remark),callback:function ($$v) {_vm.$set(row, "remark", $$v)},expression:"row.remark"}})]}}],null,false,1084974555)}),(_vm.isEdit)?_c('vxe-column',{attrs:{"title":"操作","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
            var rowIndex = ref.rowIndex;
return [_c('div',{staticClass:"operation"},[_c('i',{staticClass:"el-icon-circle-plus-outline",staticStyle:{"font-size":"18px","color":"#409eff"},on:{"click":_vm.add}}),_c('div'),_c('i',{staticClass:"el-icon-remove-outline",staticStyle:{"font-size":"18px","color":"#409eff"},on:{"click":function($event){return _vm.removeRowEvent(row, rowIndex)}}})])]}}],null,false,64188745)}):_vm._e()],1),(!_vm.isEdit)?_c('Paging',{attrs:{"pager":_vm.pager,"end":""},on:{"pagination":_vm.pagerUpdate}}):_vm._e()],1):_c('div',{staticClass:"add_set"},[_c('i',{staticClass:"el-icon-circle-plus-outline",staticStyle:{"font-size":"80px"},on:{"click":function($event){return _vm.add(true)}}}),_c('div',{on:{"click":function($event){return _vm.add(true)}}},[_vm._v("新增物流时效配置")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }