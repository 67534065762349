/*
 * @Author: your name
 * @Date: 2020-11-06 10:41:39
 * @LastEditTime: 2021-12-23 15:50:10
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \scm-admin\src\mixin\commodityInfoDict.js
 */
import { getAllCommodityDict } from '@/api/user'

const Mixin = {
  data() {
    return {
      allCommodityDict: {}
    }
  },
  computed: {
    /*     auditTypeOpt() {
      return this._getAllCommodityDict('AUDIT_STATUS')
    } */
  },
  async mounted() {
    const allCommodityDict = sessionStorage.getItem('allCommodityDict')
    if (allCommodityDict) {
      this.allCommodityDict = JSON.parse(sessionStorage.getItem('allCommodityDict') || [])
    } else {
      const { datas } = await getAllCommodityDict()
      if (Object.keys(datas).length) {
        this.allCommodityDict = datas
        // console.log(datas)
        sessionStorage.setItem('allCommodityDict', JSON.stringify(datas))
      }
    }
  },
  methods: {
    handleFormChange(form, clearList) {
      clearList.forEach(key => { this[form][key] = Array.isArray(this[form][key]) ? [] : '' })
    },
    getAuditTypeLabel(key, type) {
      // console.log(this._getAllCommodityDict(key))
      for (const v of this._getAllCommodityDict(key)) {
        if (v.val === type + '') return v.label
      }
      return type
    },
    _getAllCommodityDict(key) {
      if (!key) return []
      const LangObj = {
        'zh': 'zh-CN',
        'en': 'en-US'
      }
      const langKey = LangObj[this.$i18n.locale]
      const Arr = []
      this.allCommodityDict[key] && this.allCommodityDict[key].map(v => {
        if (v.dictName) Arr.push({ label: JSON.parse(v.dictName)[langKey], val: v.dictValue })
      })
      return Arr
    }
  }
}

export default Mixin
