<template>
  <div class="app-container">
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="平台/站点:" prop="platformSiteList">
        <el-cascader
          v-model="ruleForm.platformSiteList"
          clearable
          filterable
          :options="limitedAlldatas"
          :props=" { value: 'value', label: 'platformName', children: 'siteList'}"
          @change="
            () => {
              getUserChange('site');
              _getPage();
            }"
        />
      </el-form-item>
      <el-form-item label="物流类型:" prop="logisticsTypeDict">
        <el-radio-group
          v-model="ruleForm.logisticsTypeDict"
          :disabled="isBan"
          @change="
            () => {
              getUserChange('logisticsTypeDict');
              _getPage();
            }"
        >
          <el-radio label="0">全部</el-radio>
          <el-radio
            v-for="item in logisticsOptions"
            :key="item.val"
            :label="item.val"
            :value="item.val"
          >
            {{ item.label }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="运输方式:">
        <div style="display: flex">
          <el-checkbox
            v-model="ruleForm.checkAll"
            :disabled="isBan"
            :indeterminate="isIndeterminate"
            @change="
              (e) => {
                handleCheckAllChange(e);
                _getPage();
              }
            "
          >全选</el-checkbox>
          <div style="margin: 0px 15px" />
          <el-checkbox-group
            v-model="ruleForm.checked"
            :disabled="isBan"
            @change="
              (e) => {
                handleCheckedCitiesChange(e);
                _getPage();
              }
            "
          >
            <el-checkbox
              v-for="list in transportOptions"
              :key="list.val"
              :label="list.val"
            >{{ list.label }}</el-checkbox>
          </el-checkbox-group>
        </div>
      </el-form-item>
      <div class="submit">
        <div>
          <el-button
            v-if="!isEdit"
            v-permission="'edit'"
            type="primary"
            :disabled="!tableData.length"
            :loading="exportLoading"
            @click="edit(true)"
          >
            编辑
          </el-button>
          <el-button
            v-if="isEdit"
            type="primary"
            :loading="exportLoading"
            @click="saveForm()"
          >
            <!-- @click="validAllEvent('ruleForm')" -->
            保存
          </el-button>
          <el-button v-if="isEdit" :loading="exportLoading" @click="cancel(false)">取消</el-button>
        </div>
        <div>
          <el-button
            :loading="exportLoading"
            :disabled="isBan"
            @click="resetForm('ruleForm')"
          >重置</el-button>
          <el-button v-permission="'exportA'" :loading="exportLoading" type="primary" :disabled="isBan" @click="exportLogistics">
            导出
          </el-button>
        </div>
      </div>
    </el-form>
    <div v-if="tableData.length" class="table_con">
      <vxe-table
        ref="xTable"
        border
        resizable
        show-overflow
        :data="tableData"
        align="center"
        :edit-rules="validRules"
        :edit-config="isEdit ? { trigger: 'click', mode: 'cell' } : {}"
      >
        <vxe-column type="checkbox" width="60" />
        <vxe-column field="siteName" title="平台/站点">
          <template #default="{ row }">
            {{ `${row.platformName}/${row.siteName}` }}
          </template>
        </vxe-column>

        <vxe-column field="logisticsTypeI18" title="物流类型" />

        <vxe-column
          field="transportModeDict"
          title="运输方式"
          :edit-render="{}"
        >
          <template #default="{ row }">
            <vxe-select
              v-model="row.transportModeDict"
              :disabled="!isEdit"
              transfer
            >
              <vxe-option
                v-for="item in transportOptions"
                :key="item.val"
                :value="item.val"
                :label="item.label"
              />
            </vxe-select>
          </template>
          <template #edit="{ row }">
            <vxe-select v-model="row.transportModeDict" transfer>
              <vxe-option
                v-for="item in transportOptions"
                :key="item.val"
                :value="item.val"
                :label="item.label"
              />
            </vxe-select>
          </template>
        </vxe-column>
        <vxe-column
          field="transportDays"
          title="运输天数"
          :edit-render="{ autofocus: '.vxe-input--inner' }"
        >
          <template #default="{ row }">
            <span>{{ row.transportDays }}</span>
          </template>
          <template #edit="{ row }">
            <vxe-input
              v-model="row.transportDays"
              placeholder="请输入内容"
              min="1"
              type="integer"
            />
          </template>
        </vxe-column>
        <vxe-column
          field="remark"
          title="备注"
          :edit-render="{ autofocus: '.vxe-input--inner' }"
        >
          <template #default="{ row }">
            <span>{{ row.remark }}</span>
          </template>
          <template #edit="{ row }">
            <vxe-input
              v-model="row.remark"
              maxlength="256"
              type="text"
              placeholder="请输入内容"
            />
          </template>
        </vxe-column>
        <vxe-column v-if="isEdit" title="操作" width="120">
          <template #default="{ row, rowIndex }">
            <div class="operation">
              <i
                class="el-icon-circle-plus-outline"
                style="font-size: 18px; color: #409eff"
                @click="add"
              />
              <div />
              <i
                class="el-icon-remove-outline"
                style="font-size: 18px; color: #409eff"
                @click="removeRowEvent(row, rowIndex)"
              />
            </div>
          </template>
        </vxe-column>
      </vxe-table>
      <Paging v-if="!isEdit" :pager="pager" end @pagination="pagerUpdate" />
    </div>
    <div v-else class="add_set">
      <i
        class="el-icon-circle-plus-outline"
        style="font-size: 80px"
        @click="add(true)"
      />
      <div @click="add(true)">新增物流时效配置</div>
    </div>
  </div>
</template>
<script>
import { allPlatSiteTreeNodeList } from '@/api/scm-api'
import {
  getLogisticsAgingPage,
  saveLogisticsAging,
  exportLogisticsAging
} from '@/api/supply'
import Paging from '@/components/Pagination'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import VXETable from 'vxe-table'
import { handleDownload, handleMonitorParams } from '@/utils/index'
import { getInfo } from '@/api/user'
import { trackSiteSearch, handleBreadTitle, trackExportEvent } from '@/utils/monitor'
export default {
  components: {
    Paging
  },
  mixins: [commodityInfoDict],
  data() {
    return {
      limitedAlldatas: [],
      userName: '',
      exportLoading: false,
      isBan: false,
      isEdit: false,
      validRules: {
        transportModeDict: [{ required: true, message: '请输入' }],
        transportDays: [{ required: true, message: '请输入' }]
      },
      tableData: [],
      transportOptions: [],
      logisticsOptions: [],
      isIndeterminate: true,
      ruleForm: {
        checkAll: false,
        checked: [],
        platformSiteList: [],
        logisticsTypeDict: '0'
      },
      rules: {
        platformSiteList: [{ required: true, message: '请选择', trigger: 'change' }],
        logisticsTypeDict: [
          { required: true, message: '请选择', trigger: 'change' }
        ]
      },
      pager: {
        size: 20,
        current: 1,
        total: 0
      },
      isEmpty: true,
      deleteList: []
    }
  },
  async mounted() {
    this._limitedlistPlatformAndSite()
    this.transportOptions =
      this._getAllCommodityDict('SCM_TRANSPORT_MODE') || []
    this.logisticsOptions =
      this._getAllCommodityDict('SCM_LOGISTICS_TYPE') || []
    const { datas } = await getInfo()
    this.userName = datas.username

    const selectedSites = JSON.parse(localStorage.getItem(`logistics-site-${this.userName}`))

    if (selectedSites) {
      this.ruleForm.platformSiteList = selectedSites
    }
    const selectedType = localStorage.getItem('logistics-logisticsTypeDict')
    if (selectedType) {
      const newUser = selectedType.split('+')[0]
      const OldTypes = selectedType.split('+')[1]
      if (newUser === this.userName && OldTypes) {
        this.ruleForm.logisticsTypeDict = OldTypes
      }
    }
    this._getPage()
  },
  methods: {

    async _limitedlistPlatformAndSite() {
      const { datas } = await allPlatSiteTreeNodeList()
      this.limitedAlldatas = datas.map(data => ({
        ...data,
        siteList: data.siteList.map(item => ({
          platformId: item.siteId,
          platformName: item.siteName
        }))
      }))
      this.getDataTree(this.limitedAlldatas)
    },
    getDataTree(data) {
      if (!Array.isArray(data)) return
      for (let i = 0; i < data.length; i++) {
        data[i].value = `${data[i].platformId}&&${data[i].platformName}`
        this.getDataTree(data[i].siteList)
      }
    },
    getUserChange(type) {
      if (type === 'site') {
        return localStorage.setItem(`logistics-site-${this.userName}`, JSON.stringify(this.ruleForm.platformSiteList))
      }
      if (type === 'logisticsTypeDict') {
        const selectedType = `${this.userName}+${this.ruleForm.logisticsTypeDict}`
        localStorage.setItem('logistics-logisticsTypeDict', selectedType.toString())
      }
    },

    async _getPage(obj = {}) {
      const { platformSiteList: array, logisticsTypeDict, checkAll, checked } = this.ruleForm
      const [platformId, siteId] = array.map(item => Number(item.split('&&')[0]))
      const newLogisticsType = logisticsTypeDict === '0' ? [] : [logisticsTypeDict]
      const params = {
        logisticsTypeDictList: newLogisticsType,
        platformSiteList: array.length ? [{ platformId, siteId }] : [],
        transportModeDictList: checkAll ? [] : checked,
        ...obj
      }

      const {
        datas: {
          pager: { total },
          records = []
        }
      } = await getLogisticsAgingPage(params, this.pager)
      this.tableData = records
      this.pager.total = total
      this.isEmpty = total === 0
      trackSiteSearch(
        JSON.stringify(handleMonitorParams(params)),
        handleBreadTitle(this.$route),
        this.pager.size
      )
    },
    add() {
      const { platformSiteList, logisticsTypeDict } = this.ruleForm

      const [platformId, platformName] = platformSiteList[0].split('&&')
      const [siteId, siteName] = platformSiteList[1].split('&&')

      if (!platformSiteList.length || logisticsTypeDict === '0') {
        this.$message.error('请选择平台/站点、物流类型 !')
        return
      }
      this.isBan = true
      this.isEdit = true
      const objs = this.logisticsOptions.filter(
        (item) => item.val === logisticsTypeDict
      )[0]
      this.tableData.unshift({
        id: '',
        logisticsTypeDict: objs?.val,
        logisticsTypeI18: objs?.label,
        transportModeDict: '',
        transportModeI18: '',
        transportDays: '',
        remark: '',
        platformId: Number(platformId), platformName, siteId: Number(siteId), siteName
      })
    },
    cancel() {
      this._getPage()
      this.isEdit = false
      this.isBan = false
      this.restDelete()
    },
    addRules() {
      this.add()
    },
    deleteRules(index) {
      console.log('%c Line:312 🥝 index', 'color:#2eafb0', index)
    },
    async removeRowEvent(row, index) {
      const type = await VXETable.modal.confirm('您确定要删除该数据?')
      const $table = this.$refs.xTable
      if (type === 'confirm') {
        if (Array.isArray(this.tableData) && (this.tableData.length === 1) && this.isEmpty) {
          this.tableData.map(item => {
            item.transportDays = ''
            item.transportModeDict = ''
            item.remark = ''
          })
          return
        }
        if (row.id) {
          this.deleteList.push(row)
        }
        this.tableData.splice(index, 1)
        $table.remove(row)
        this.$message.success('删除成功 !')
      }
    },
    async restDelete() {
      this.deleteList = []
    },
    handleCheckAllChange(val) {
      this.ruleForm.checked = val
        ? this.transportOptions.map((v) => v.val)
        : []
      this.isIndeterminate = false
    },
    handleCheckedCitiesChange(value) {
      const checkedCount = value.length
      this.ruleForm.checkAll = checkedCount === this.transportOptions.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.transportOptions.length
    },
    saveForm() {
      if (this.tableData.length) return this.validAllEvent()
      else return this.submitForm()
    },
    async validAllEvent() {
      const $table = this.$refs.xTable
      const errMap = await $table.validate(true).catch((errMap) => errMap)

      if (errMap) {
        VXETable.modal.message({ status: 'error', content: '校验不通过！' })
      } else {
        VXETable.modal.message({ status: 'success', content: '校验成功！' })
        console.log('%c Line:327 🌮 errMap', 'color:#ed9ec7', this.tableData)

        this.submitForm()
      }
    },

    async submitForm(formName) {
      try {
        this.exportLoading = true
        const { logisticsTypeDict } = this.ruleForm
        const { platformId, siteId } = this.tableData[0]
        const params = {
          itemList: this.tableData,
          logisticsTypeDict,
          deleteIdList: this.deleteList.map(item => item.id),
          platformId, siteId
        }
        await saveLogisticsAging(params)
        this.$message.success('操作成功 !')
        this.restDelete()
        this.isBan = false
        this.isEdit = false
        this.exportLoading = false
      } finally {
        this.exportLoading = false
      }
    },
    edit(isEdit) {
      const { platformSiteList, logisticsTypeDict } = this.ruleForm
      if (!platformSiteList.length || logisticsTypeDict === '0') {
        this.$message.error('请选择平台/站点、物流类型 !')
        return
      }
      this.isBan = true
      this.isEdit = true
      this._getPage({ size: 9999, current: 1 })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.ruleForm.checkAll = false
      this.ruleForm.checked = []
      this._getPage()
    },
    pagerUpdate(val) {
      this.pager = val
      this._getPage()
      // this._orderpage()
    },
    async exportLogistics() {
      try {
        this.exportLoading = true
        const multipleTable = this.$refs.xTable?.getCheckboxRecords() || []

        const params = {}
        if (multipleTable.length) {
          Object.assign(params, {
            idList: multipleTable.map((item) => item.id)
          })
        } else {
          const { platformSiteList: array, logisticsTypeDict, checkAll, checked } =
            this.ruleForm
          const [platformId, siteId] = array.map(item => Number(item.split('&&')[0]))

          Object.assign(params, {
            logisticsTypeDictList: logisticsTypeDict != '0' ? [logisticsTypeDict] : [''],
            platformSiteList: array.length ? [{ platformId, siteId }] : [],
            transportModeDictList: checkAll ? [] : checked
          })
        }
        const data = await exportLogisticsAging(params)
        handleDownload(data, '物流时效配置.xlsx')
        this.exportLoading = false
        trackExportEvent(handleBreadTitle(this.$route))
      } finally {
        this.exportLoading = false
        // this.clearSelection()
      }
    },
    getSelectEvent() {
      const selectRecords = this.$refs.xTable.getCheckboxRecords()
      console.log(
        '%c Line:432 🍌 selectRecords',
        'color:#2eafb0',
        selectRecords
      )
    }
  }
}
</script>
<style lang="scss" scope>
.radio_list {
  .el-radio {
    border-radius: 30px;
    height: 36px;
    margin: 5px 0;
    .el-radio__input {
      display: none;
    }
  }
}
.submit {
  display: flex;
  justify-content: space-between;
}
.table_con {
  margin-top: 15px;
}
.operation {
  display: flex;
  justify-content: center;
  > div {
    margin: 0 10px;
  }
}
.add_set {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a9b7b7;
}
</style>
